
<body class="style_3">

    <div class="wrapper_centering" style="margin:0">
        <div class="container_centering">
            <div class="container">

                <div class="row justify-content-between">
                    <div class="col-xl-6 offset-xl-3">
                        <div class="main_title_1 text-center">
                            <h1><p innerHTML="{{this.message}}"></p></h1>

                        </div>
                    </div>

                    <!-- /col -->
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container_centering -->
    </div>
    <!-- /wrapper_centering -->
</body>
