import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public APIKEY: any = "2992c9debd79d6e9";
  // public BaseUrl: any = 'https://api.linkinfotech.com/webservices/';
  public BaseUrl: any = 'https://cawiapi.techbuddiesit.com/webservices/';
  public: any = '';
  public clientlogourl: any = '';

  constructor(
    private http: HttpClient,
    private matSnackBar: MatSnackBar
  ) {
  }

  postApi(data: any, method: any): Observable<any> {

    data.APIKEY = this.APIKEY;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    // console.log(data, method);
    return this.http.post<any>(this.BaseUrl + method, JSON.stringify(data), { headers: headers }).pipe(
      tap((data) => console.log()
      ),
      catchError(this.handleError<any>('Login'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // alert("Server error/Internet connectivity")
      console.error(error); // log to console instead
      console.log('Failed :: while hit service due to internet or server occurrence');
      return of(result as T);
    };
  }

  showToast(message: string, duration?: number, action?: string,
    hPosition?: any, vPosition?: any,
    className?: any) {

    try {
      this.matSnackBar.open(message, action, {
        duration: duration ? duration : 5000,
        horizontalPosition: hPosition ? hPosition : 'center',
        verticalPosition: vPosition ? vPosition : 'bottom',
        panelClass: className
        // direction: "rtl"
      });
    } catch (error) {

    }

  }

}
