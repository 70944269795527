import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage-service';
import * as $ from 'jquery';

@Component({
    selector: 'app-customer-auth',
    templateUrl: './supplier-auth.component.html',
    styleUrls: ['./supplier-auth.component.css']
})
export class SupplierAuthComponent implements OnInit {

    public apiInputs = { "project_id": "", "supplier_id": "", "url":"","uid":"","utm_source": "", "utm_medium": "", "utm_term": "", "campaign_id": "","affiliate":0};
    public client: any = '';
    public store: any = '';
    public token: string = '';

    public otpResendTimer: number = 120;
    public otpResendStatus: boolean = false;
    public otpResendInterval: any;
    public otpSendStatus: boolean = false;
    public logoimage='';
    public clientlogo ='';
    public projectid: any = '';
    public supplierid: any = '';
    public url: any = '';
    public uid: any = '';
    public message = "Welcome";
    public utm_source = '';
    public utm_medium = '';
    public utm_term = '';
    public campaign_id= '';
   
    constructor(
        public apiService: ApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService
        
    ) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
           

            if (typeof params.projectid != 'undefined' && params.projectid != '' && params.projectid != null &&
                typeof params.supplierid != 'undefined' && params.supplierid != '' && params.supplierid != null
            ) {
                this.projectid = params.projectid;
                this.supplierid = params.supplierid;
                this.url = params?.url;
                this.uid = params?.uid;
                
                this.apiInputs.project_id = this.projectid;
                this.apiInputs.supplier_id = this.supplierid;
                this.apiInputs.url = this.url;
                this.apiInputs.uid = this.uid;
                this.apiInputs.affiliate = params?.affiliate;
                if (typeof params.utm_source != 'undefined' && params.utm_source != '' && params.utm_source != null) {
                    this.apiInputs.utm_source = params.utm_source;
                }
                if (typeof params.utm_medium != 'undefined' && params.utm_medium != '' && params.utm_medium != null) {
                    this.apiInputs.utm_medium = params.utm_medium;
                }
                if (typeof params.utm_term != 'undefined' && params.utm_term != '' && params.utm_term != null) {
                    this.apiInputs.utm_term = params.utm_term;
                }
                if (typeof params.campaign_id != 'undefined' && params.campaign_id != '' && params.campaign_id != null) {
                    this.apiInputs.campaign_id = params.campaign_id;
                }
            }
            else {
                this.router.navigate(['not-found']);
            }
        });
    }

    ngOnInit(): void {
        this.checkurl();
    }
    
     checkurl(){
        this.apiService.postApi(this.apiInputs, 'user/checkProjectData').subscribe((result) => {
            if (result.status == 1) {
                
                decodeURI(result.url);
                window.location = result.url;
            }else{
                this.message = result.message;
            } 

        }, (err) => {

            this.router.navigate(['not-found']);
            console.log("Client/feedback_list", JSON.stringify(err));
        });
     }

   

    

    

    

   
}
