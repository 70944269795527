import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { SupplierAuthComponent } from './pages/supplier-auth/supplier-auth.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
    
    { path: '', redirectTo: 'not-found', pathMatch: 'full' },
    { path: 'survey/home', component: HomeComponent },
    { path: 'survey/supplier-auth', component: SupplierAuthComponent },
    { path: 'survey/survey', component: SurveyComponent },
    { path: 'survey/thankyou', component: ThankyouComponent },
    { path: 'survey/not-found', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
