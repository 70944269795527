import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
    
    captchaResponse: string = '';
    public apiInputs ={ "project_id": "", "supplier_id":"", "token":"","ip_address":"","form_data": "","udid":"","question_id":0};
    public feedbackSet: any = [];
    public feedbackQuestions: any = [];
    public currentIndex: number = 1;
    public totalQuestion: number = 7;
    public apiRequestStatus: boolean = false;
    public currentCustomer: any = {};
    public client: any = '';
    public store: any = '';
    public token: any = '';
    public logoimage ='';
    public clientlogo = '';
    public todate = new Date();
    public embeded_url: any = "";
    public is_boat:any;
    public nextques:any;
    public question_id:any;
  


    constructor(
        public apiService: ApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        public sanitized: DomSanitizer,
    ) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            

            if (typeof params.projectid != 'undefined' && params.projectid != '' && params.projectid != null &&
                typeof params.supplierid != 'undefined' && params.supplierid != '' && params.supplierid != null
            ) {
                this.client = params.projectid;
                this.store = params.supplierid;
                this.token = params?.token;
                this.apiInputs.project_id = this.client;
                this.apiInputs.supplier_id = this.store;
                this.apiInputs.token = this.token;
                this.apiInputs.udid = params.udid;
            }
            else {
               // this.router.navigate(['survey/not-found']);
            }
        });
    }
     siteKey: string = "6LfasBgnAAAAAKKZT83C7zjDVG0fnjVVwj_RlI1e";
     secretKey: string = "6LfasBgnAAAAAKm4rCVh74G_xWcyOkKd3J1rd3UU";

    // siteKey: string = "6LeQNi8nAAAAAHU2g0uZdV0atHvsWwqYb8Vsyi2S";
    // secretKey: string = "6LeQNi8nAAAAAO5OynZitX9i8DPzP7TddzOnIHCF";

    ngOnInit(): void {

        this.getCustomer();
        this.get_feedback_list();
        
        
    }
    handleCaptchaResponse(response: string): void {
        this.captchaResponse = response;
        console.log('this.captchaResponse',this.captchaResponse);
      }

    async getCustomer() {
        var currentCustomerTemp:any = await this.localStorageService.getItem("currentCustomer");
        this.currentCustomer = JSON.parse(currentCustomerTemp);
        console.log("this.currentCustomer", this.currentCustomer);
    }

    showLoader() {
        $("#loader_form").show();
    }

    hideLoader() {
        $("#loader_form").hide();
    }

    get_feedback_list(data=0){

        this.showLoader();
        if(data !=0){
            this.apiInputs.question_id = data;
        }
        this.apiService.postApi(this.apiInputs, 'user/getProjectQuestion').subscribe((result) => {
            this.hideLoader();
            if (result.status) {
                this.apiRequestStatus = true;
                 console.log('feedback_list result', result);
                 
                //this.feedbackSet = result?.data?.feedback_set;
                this.feedbackQuestions = result?.data;
                this.totalQuestion = this.feedbackQuestions.length;
                this.is_boat = result.is_boat;
               // this.logoimage  = result.client_logo;
                //this.clientlogo = this.apiService.clientlogourl;

            }
            else {
                this.router.navigate(['survey/not-found']);
            }

        }, (err) => {
            this.hideLoader();
            this.router.navigate(['not-found']);
            console.log("user/getProjectQuestion", JSON.stringify(err));
        });
    }

    prev(index = 0) {
        console.log("prev pre", this.currentIndex)
        if (this.currentIndex > 1) {
            this.currentIndex -= 1;
            console.log("prev post", this.currentIndex)
        }
    }

    next(index = this.currentIndex) {
        // console.log("index", (index-1));
        // console.log("this.feedbackQuestions", this.feedbackQuestions);

        if (typeof this.feedbackQuestions[(index - 1)]?.ans_data == 'undefined' ||
            this.feedbackQuestions[(index - 1)]?.ans_data == null ||
            this.feedbackQuestions[(index - 1)]?.ans_data == '') {
            this.apiService.showToast("This is required field.", 1000);
            return false;
        }

        console.log("next pre", this.currentIndex);
        if (this.currentIndex < this.totalQuestion) {
            this.currentIndex += 1;
        }
        console.log("next post", this.currentIndex);

        return true;
    }

    submit() {
        if(this.is_boat == 1){
        if(this.captchaResponse == ''){
            this.apiService.showToast("Please select recaptcha value.", 2000);
            return false;
        }
    }
        if (typeof this.feedbackQuestions[(this.currentIndex - 1)]?.ans_data == 'undefined' ||
            this.feedbackQuestions[(this.currentIndex - 1)]?.ans_data == null ||
            this.feedbackQuestions[(this.currentIndex - 1)]?.ans_data == '') {
                this.apiService.showToast("This is required field.", 2000);
               return false;
        }
        this.showLoader();
        this.apiInputs.form_data = JSON.stringify(this.feedbackQuestions);
        
         console.log("this.feedbackQuestions", this.feedbackQuestions);
        
        this.apiService.postApi(this.apiInputs, 'user/submit_feedback').subscribe((result) => {
            this.hideLoader();
            
            if(result.status==1){
                 decodeURI(result.url);     
                 window.location = result.url;
                } else if (result.status == 11) {
                    decodeURI(result.url);
                    this.embeded_url = this.sanitized.bypassSecurityTrustResourceUrl(result.url);
                }else{
                    this.nextques = result.next_question_id;
                    this.get_feedback_list(this.nextques);
                } 
        }, (err) => {

            console.log("user/getProjectQuestion", JSON.stringify(err));
        });
    
    }

    parseInt(val: any): number {
        return parseInt(val) || 0;
    }

    setAnswer(_this: any, index = 0, value: any, extra = null) {
        this.feedbackQuestions[index].ans_data = value;
        console.log("answerSetsTemp[index]->" + index, this.feedbackQuestions[index]);
    }

    setMultiAnswer(_this: any, index = 0, value: any, extra = null) {
        this.feedbackQuestions[index].ans_data = value;
        console.log("answerSetsTemp[index]->" + index, this.feedbackQuestions[index]);
    }



}
