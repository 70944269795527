<div *ngIf="embeded_url !=''">
    <iframe width="100%" style="min-height: 90vh;" [src]="embeded_url" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>

<body class="style_3" *ngIf="embeded_url ==''">
    <div class="wrapper_centering" style="margin:0">
        <div class="container_centering">
            <div class="container">
                <div class="row justify-content-between">
                    <h3>
                        <!-- <img src="{{clientlogo}}{{logoimage}}" alt="Logo" style="margin-top: -40px;">  -->
                    </h3>

                    <div class="col-xl-6 offset-xl-3" style="margin-top: 40px">
                        <div id="wizard_container">

                            <form id="wrapped" method="POST" autocomplete="off" *ngIf="apiRequestStatus">
                                <input id="website" name="website" type="text" value="">
                                <!-- Leave for security protection, read docs for details -->
                                <div id="middle-wizard">

                                    <div class="item-row"
                                        *ngFor="let question of feedbackQuestions; let myIndex = index; ">





                                        <!-- Start question 3 -->
                                        <div *ngIf="question?.control_type == 3"
                                            [ngClass]="{'step1':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="form-group">
                                                <div class="fl-wrap fl-wrap-textarea">
                                                    <!-- <label for="additional_message_label" class="fl-label">{{question?.ques_data?.input_box_title}}</label> -->
                                                    <input type="date" [(ngModel)]="feedbackQuestions[myIndex].ans_data"
                                                        ng-cut="$event.preventDefault()"
                                                        ng-copy="$event.preventDefault()"
                                                        ng-paste="$event.preventDefault()"
                                                        class="form-control fl-textarea"
                                                        max="{{todate | date:'yyyy-MM-dd'}}">

                                                </div>
                                            </div>
                                        </div>
                                        <!-- End question 3 -->
                                        <div *ngIf="question?.control_type == 4"
                                            [ngClass]="{'step1':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="review_block_numbers">
                                                <ul class="clearfix option_container">
                                                    <div class="row" style="width: 80%;">
                                                        <!-- <div class="col-12"
                                                            *ngFor="let item of question?.ques_data; let itemIndex = index;">
                                                            <li>
                                                                <div class="container_numbers">
                                                                    <label
                                                                        [ngClass]="{'radio':true, 'number-selected':question?.ans_data == item}"
                                                                        (click)="setAnswer($event, myIndex, item)">
                                                                        {{item}}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </div> -->
                                                        <div class="col-12">
                                                            <li>
                                                            <ng-select
                                                            bindLabel="name"
                                                            appendTo="body"
                                                            
                                                            [(ngModel)]="feedbackQuestions[myIndex].ans_data">
                                                            <ng-option *ngFor="let item of question?.ques_data; let itemIndex = index;"  value={{item}}>{{item}}</ng-option>
                                                          </ng-select>
                                                          </li>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- Start question 4 -->

                                      <div *ngIf="question?.control_type == 1"
                                            [ngClass]="{'step1':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="review_block_numbers">
                                                <ul class="clearfix option_container">
                                                    <div class="row" style="width: 80%;">
                                                        <!-- <div class="col-12">
                                                            <li>
                                                            <ng-select
                                                            bindLabel="name"
                                                            appendTo="body"
                                                            
                                                            [(ngModel)]="feedbackQuestions[myIndex].ans_data">
                                                            <ng-option *ngFor="let item of question?.ques_data; let itemIndex = index;"  value={{item}}>{{item}}</ng-option>
                                                          </ng-select>
                                                          </li>
                                                        </div> -->
                                                        <div class="col-12">
                                                            <li>
                                                            <input list="browsers"  [(ngModel)]="feedbackQuestions[myIndex].ans_data" style="width: 100%;" max="20" class="form-control fl-textarea ng-untouched ng-pristine ng-valid">
                                                            <datalist id="browsers">
                                                                <option *ngFor="let item of question?.ques_data; let itemIndex = index;" [value]="item">

                                                            </datalist>
                                                        </li>
                                                        </div>



                                                        <!-- <div class="col-12">
                                                            <li>
                                                            <ng-select
                                                            bindLabel="name"
                                                            appendTo="body"
                                                            
                                                            [(ngModel)]="feedbackQuestions[myIndex].ans_data">
                                                            <ng-option *ngFor="let item of question?.ques_data; let itemIndex = index;"  value={{item}}>{{item}}</ng-option>
                                                          </ng-select>
                                                          </li>
                                                        </div> -->



                                                        <!-- <ng-select [(ngModel)]="feedbackQuestions[myIndex].ans_data" class="sumo-select">
                                                            <option value="">Select {{question?.title}}</option>
                                                            <option *ngFor="let item of question?.ques_data; let itemIndex = index;"  value={{item}}>{{item}}</option>
                                                           
                                                        </ng-select>
                                                     -->

                                                        <!-- <div class="col-12"
                                                            *ngFor="let item of question?.ques_data; let itemIndex = index;">
                                                            <li>
                                                                <div class="container_numbers">
                                                                    <label
                                                                        [ngClass]="{'radio':true, 'number-selected':question?.ans_data == item}"
                                                                        (click)="setAnswer($event, myIndex, item)">
                                                                        {{item}}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </div> -->
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End question 4 -->

                                        <!-- Start question 5 -->
                                        <div *ngIf="question?.control_type == 5"
                                            [ngClass]="{'step':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="review_block_numbers">
                                                <ul class="clearfix">
                                                    <li
                                                        *ngFor="let item of [].constructor(parseInt(question?.ques_data?.face_count)); ;let itemIndex = index">
                                                        <div class="container_numbers">
                                                            <label
                                                                [ngClass]="{'radio':true, 'number-selected':question?.ans_data == (itemIndex+1)}"
                                                                (click)="setAnswer($event, myIndex, (itemIndex+1))">
                                                                <img style="height: 40px;"
                                                                    src="assets/img/icons/face/1.png">
                                                            </label>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End question 5 -->

                                        <!-- Start question 6 -->
                                        <div *ngIf="question?.control_type == 6"
                                            [ngClass]="{'step1':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="form-group">
                                                <div class="fl-wrap fl-wrap-textarea">
                                                    <!-- <label for="additional_message_label" class="fl-label">{{question?.ques_data?.textarea_box_title}}</label> -->
                                                    <textarea [(ngModel)]="feedbackQuestions[myIndex].ans_data"
                                                        (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                                                        (paste)="$event.preventDefault()"
                                                        [ngModelOptions]="{standalone: true}"
                                                        class="form-control fl-textarea" style="height:180px;"
                                                        placeholder="{{question?.ques_data?.textarea_box_placeholder}}"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End question 6 -->

                                        <!-- Start question 7 -->
                                        <div *ngIf="question?.control_type == 2"
                                            [ngClass]="{'step1':true, 'd-none':currentIndex != (myIndex+1)}">
                                            <h3 class="title">{{question?.title}}</h3>
                                            <h3 class="title">{{question?.question}}</h3>
                                            <div class="form-group">
                                                <div class="fl-wrap fl-wrap-textarea">
                                                    <!-- <label for="additional_message_label" class="fl-label">{{question?.ques_data?.input_box_title}}</label> -->
                                                    <textarea [(ngModel)]="feedbackQuestions[myIndex].ans_data"
                                                        (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                                                        (paste)="$event.preventDefault()"
                                                        [ngModelOptions]="{standalone: true}"
                                                        class="form-control fl-textarea" style="height:80px;"
                                                        placeholder="{{question?.ques_data?.input_box_placeholder}}"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End question 7 -->
                                    </div>

                                    <div class="row justify-content-between mt-2">
                                        <div class="col-12" *ngIf="is_boat =='1'"
                                            [ngStyle]="{'display':currentIndex == totalQuestion?'block':'none'}">
                                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
                                                [(ngModel)]="captchaResponse">
                                            </ngx-recaptcha2>
                                            <!-- <ngx-recaptcha2 [(ngModel)]="captchaResponse" [siteKey]="siteKey" (captchaResponse)="handleCaptchaResponse($event)"></ngx-recaptcha2> -->

                                        </div>
                                        <div class="col-6">
                                            <button [disabled]="currentIndex == 1" type="submit" name="process"
                                                class="submit" (click)="prev()">Prev</button>
                                        </div>

                                        <div class="col-6 "
                                            [ngStyle]="{'display':currentIndex == totalQuestion?'none':'block'}">
                                            <button [disabled]="currentIndex == totalQuestion" type="submit"
                                                name="process" class="submit" (click)="next()">Next</button>
                                        </div>

                                        <div class="col-6"
                                            [ngStyle]="{'display':currentIndex == totalQuestion?'block':'none'}">

                                            <button type="submit" name="process" class="submit"
                                                (click)="submit()">Submit</button>
                                        </div>
                                    </div>

                                </div>
                                <!-- /middle-wizard -->
                            </form>
                        </div>
                        <!-- /Wizard container -->
                    </div>
                    <!-- /col -->
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container_centering -->

    </div>

    <!-- Using Items Input -->

    <!-- /wrapper_centering -->

</body>
<script>

</script>