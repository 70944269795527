<body class="style_3">
    <div class="wrapper_centering" style="margin:0">
        <div class="container_centering">
            <div class="container">
                <div class="row justify-content-between">
                    <h3>
                        <!-- <img src="{{clientlogo}}{{logoimage}}" alt="Logo" style="margin-top: -235px;">  -->
                    </h3>
                    <div class="col-xl-12 ">
                        <div class="main_title_1 text-center">
                          
                            <!-- <p>Thankyou</p> -->
                            <p>{{this.message}}</p>
                        </div>
                    </div>
                    <!-- /col -->
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container_centering -->
    </div>
    <!-- /wrapper_centering -->
</body>