import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }
    async setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    async getItem(key: string) {
        return localStorage.getItem(key)
    }
    async removeItem(key: string) {
        localStorage.removeItem(key);
    }
    async clear() {
        localStorage.clear();
    }
}