import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage-service';
import * as $ from 'jquery';

@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

    public apiInputs = { "token":"","status":"","message":"","usertoken":""};
    public client: any = '';
    public store: any = '';
    public token: any = '';
    public logoimage = '';
    public clientlogo = '';
    public message = '';
    public ipaddress = '';
    public status = '';
    public country = '';
    public mystatus = '';
    public currentCustomerTemp ='';
    public currentCustomer = '';
    public usertoken = '';
    public customer: any = '';

    constructor(
        public apiService: ApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService
    ) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if(typeof params.uid != 'undefined' && params.uid != '' && params.uid != null
            )
            {
                this.usertoken = params?.uid;
                
                this.message = params.message;
                if(typeof params.mystatus != 'undefined' && params.mystatus != '' && params.mystatus != null
                ){
                    this.status=params.mystatus;
                }else{
                    this.status=params.status;
                }
                
                this.apiInputs.status = this.status;
                this.apiInputs.token = this.usertoken;
                this.apiInputs.message = this.message;
                
            }
            else{
               // this.router.navigate(['not-found']);
            }
        });
    }

    ngOnInit(): void {

        this.get_feedback_list();
    }

   
    get_feedback_list():any {
        this.customer = this.localStorageService.getItem("currentCustomer");
        this.apiInputs.usertoken = this.customer.__zone_symbol__value;
       console.log('this.apiInputs',this.apiInputs);
       this.showLoader();
       this.apiService.postApi(this.apiInputs, 'user/updateRespondentData').subscribe((result) => {
        this.hideLoader();
        if(result.status == 1){
            this.message = result.message
            decodeURI(result.url);
            window.location = result.url;

        }

    }, (err) => {
        
        this.hideLoader();
       // this.router.navigate(['not-found']);
        console.log("Client/feedback_list", JSON.stringify(err));
    });
    }

    showLoader() {
        $("#loader_form").show();
    }

    hideLoader() {
        $("#loader_form").hide();
    }
}
